<div class="example-container" dir="rtl">
            
  <button class="close" mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
  
    <mat-slide-toggle  [(ngModel)]="this.status" ngDefaultControl ></mat-slide-toggle>
    <h1>עריכת מסר שיווקי</h1>
     <br>             
  <mat-form-field appearance="standard" class="full-widthfield">
    <mat-label>הודעה</mat-label>
    <textarea matInput  [(ngModel)]="this.msg" ngDefaultControl  ></textarea>
  </mat-form-field>
  <br>
  <mat-form-field appearance="standard" class="full-width">
    <mat-label>רצף</mat-label>
    <mat-select  name ="frequency_name" [(ngModel)]="this.frequency_name" ngDefaultControl  >
      <mat-option *ngFor= "let frequency of frequencies" [value]= "frequency.frequency_name">{{frequency.frequency_name}} </mat-option>             
    </mat-select>
  </mat-form-field>
  
  <mat-form-field appearance="standard" class="full-width">
    <mat-label>ענף</mat-label>
    <input matInput [(ngModel)]="this.industry_name" ngDefaultControl  type="text" >
  </mat-form-field>

  <mat-form-field appearance="standard" class="full-width">
    <mat-label>יום</mat-label>
    <mat-select  name="day_name" [(ngModel)]="this.day_name" > 
      <mat-option *ngFor= "let day of days" [value]="day.day" required>
        {{day.day}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="standard" class="full-width">
    <mat-label>שעה</mat-label> 
    <input matInput type="time" [(ngModel)]="this.time_sendind">
    <!-- <ngx-timepicker-field [format]="24" [defaultTime]="'23:11'" [(ngModel)]="this.time_sendind"></ngx-timepicker-field> -->
    <!-- <div date="false" time="true" placeholder="Time" minutes="true"
    min-date="minDate" type="time" id="time2" format="HH:mm" short-time="false" ng-model="timeutc"
    class=" md-input"></div> -->
  </mat-form-field>

  <mat-form-field appearance="standard" class="full-width num_repetitions" >
  <mat-label>מספר חזרות</mat-label>
    <input matInput [(ngModel)]="this.num_repetitions" ngDefaultControl  type="number" >
  </mat-form-field>

  <mat-form-field appearance="standard" class="full-width note">
    <input matInput>
  <mat-label>הערה פנימית</mat-label>
    <textarea matInput [(ngModel)]="this.note" ngDefaultControl ></textarea>
  </mat-form-field>

 <button mat-raised-button color="warn" class="sumbit" (click)="submit()" >שמור</button>  

</div>