import { Injectable } from '@angular/core';
import {Customer} from '../models/customer';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { env } from 'process';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private url:string=environment.base_url+"customer";

  constructor(private http:HttpClient) { }

  public uploadCustomerFile(data:Customer[])
  {
    console.log(data);
    return this.http.post<Customer[]>(this.url+"/upload_customer_details",data);
  }
}
