import { PopUpComponent } from './../pop-up/pop-up.component';
import { Message } from './../models/message';
import { element } from 'protractor';
import { IndustyService } from './../services/industy.service';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog ,MatDialogConfig } from '@angular/material/dialog'
import {MessageService} from '../services/message.service';
import { frequencies } from '../models/frequency'
import { Industy } from '../models/industry';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { style } from '@angular/animations';
import * as XLSX from 'xlsx';
import { CustomerService } from '../services/customer.service';
import { Customer } from '../models/customer';
import { MatSort } from '@angular/material/sort';


interface day {
  dayOfWeek: number;
  day: string;
}
@Component({
  selector: 'app-table-details',
  templateUrl: './table-details.component.html',
  styleUrls: ['./table-details.component.scss']
})

export class TableDetailsComponent implements OnInit,AfterViewInit
 {

  public frequencies = frequencies;
  private data:Customer[];
  public isLoading=false;
  message:Message[]=[];
  public dataSource;

  public days: day[] = [
    {dayOfWeek:1 ,day:'ראשון'},
    {dayOfWeek:2, day:'שני'} ,
    {dayOfWeek:3 ,day:'שלישי'} ,
    {dayOfWeek:4 , day: 'רביעי'},
    {dayOfWeek:5 , day:'חמישי'} ,
    {dayOfWeek:6, day:'שישי'},
    {dayOfWeek:7, day:'מוצאי שבת'}];

    displayedColumns: string[] = ['dayOfWeek','time_sendind','num_repetitions','frequency', 'message', 'industry_id','status'];

   @ViewChild('background') background:ElementRef;
   @ViewChild(MatSort) sort: MatSort;

    constructor(private dialog :MatDialog,
    private messageService:MessageService,
    private customerService:CustomerService)
     {}

    ngAfterViewInit(): void {
      
    }

    ngOnInit(): void {
     this.GetMessages();
  }

  create(): void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
        message:null,
       // industries:this.industyService.industries
    };
    this.dialog.open(PopUpComponent, dialogConfig);
    this.GetMessages();
   // this.message.status = this.status;

  }

  edit(row:Message){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
        message:row,
        //industries:this.industyService.industries
    }; 
    this.dialog.open(PopUpComponent, dialogConfig);

    // this.dialog.afterClosed().subscribe(result => {
    //   this.message = result;
    // });

  }

  GetMessages(){
    this.messageService.getAllMessages().subscribe(res=>
      {
        this.message=res;
        console.log(this.message);
        this.dataSource = new MatTableDataSource(this.message);
        this.dataSource.sort = this.sort;
      });
   
  }

  // test(message){
  //   *ngIf=""
  // }
  delete(id:number){
     this.messageService.deleteMessage(id).subscribe(res=>{
      // this.dialog.closeAll();
      this.GetMessages();
       
     });
  
  }
  onFileChange(evt: any) {
    /* wire up file reader */
    this.isLoading=true;
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const data: string = e.target.result;
      const workbook: XLSX.WorkBook = XLSX.read(data, { type: 'binary',cellText:false,cellDates:true
     });

      /* grab first sheet */
      const wsname: string = workbook.SheetNames[0];
      const ws: XLSX.WorkSheet = workbook.Sheets[wsname];

      /* save data */
      let columns=["id","customer_name","phone_number","industry_id","days_sending","aggent_name","aggent_phone"];
      this.data = (XLSX.utils.sheet_to_json(ws, { header: columns,raw:false,dateNF:'yyyy-mm-dd' }));
      this.data=this.data.slice(1);
      console.log(this.data);
      //send the data:
      this.customerService.uploadCustomerFile(this.data).subscribe(res=>{this.isLoading=false;})
    };
    reader.readAsBinaryString(target.files[0]);
  }
}

