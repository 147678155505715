import { Message } from './../models/message';
import { MessageService } from './../services/message.service';
import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Frequency, frequencies } from '../models/frequency'
import { Industy } from '../models/industry';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IndustyService } from './../services/industy.service';
import { table } from 'console';


interface day {
  dayOfWeek: number;
  day: string;
}


@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent implements OnInit {


  formGroup: FormGroup;
 // @Input() message:Message=null;
  public frequencies = frequencies;
 // @Input() industy:Industy[]=[];
  days: day[] = [
    {dayOfWeek:1 ,day:'ראשון'},
    {dayOfWeek:2, day:'שני'} ,
    {dayOfWeek:3 ,day:'שלישי'} ,
    {dayOfWeek:4 , day: 'רביעי'},
    {dayOfWeek:5 , day:'חמישי'} ,
    {dayOfWeek:6, day:'שישי'},
    {dayOfWeek:7, day:'מוצאי שבת'}];

  constructor(private messageService:MessageService,
    public dialogRef: MatDialogRef<PopUpComponent>,
  @Inject(MAT_DIALOG_DATA)public data ) { }

  msg:string=this.data.message?this.data.message.message:"";
  frequency_name:string=this.data.message?(frequencies[this.data.message.frequency_id-1]).frequency_name:"";
  industry_name:string=this.data.message?this.data.message.industry_id:"";	
  day_name:string=this.data.message?this.getdayOfWeek(this.data.message.dayOfWeek):"";	
  status:boolean=this.data.message?this.data.message.status:false;	
  note:string=this.data.message?this.data.message.note:"";	
  num_repetitions:number=this.data.message?this.data.message.num_repetitions:0;			
  time_sendind:Date= this.data.message?this.data.message.time_sendind:new Date("10:45");	

  // message = new FormControl('', Validators.required);

  ngOnInit(): void {
    console.log(this.data);
  }


  GetFrequency_Id(name:string){
    let found  = this.frequencies.find(element => element.frequency_name==name);
    if(found)
     return found.id;
   }
   Getday(day:string){
    let found  = this.days.find(element => element.day==day);
    if(found)
     return found.dayOfWeek;
   }
   getdayOfWeek(dayOfWeek:number):string{
    let found  = this.days.find(element => element.dayOfWeek==dayOfWeek);
    if(found)
     return found.day;
   }
  submit(){
    if(this.data.message)
      this.edit();
    else
      this.create();
  }
  onetime(){
   this.data.message.num_repetitions.addEventListener("click", (e) =>{
    if(this.data.message.frequencies.id == 1) {
      this.data.message.num_repetitions.setAttribute("disabled", true);
      alert("fill field");
  
    } if(this.data.message.frequencies.id.value == 2) {
      this.data.message.num_repetitions.setAttribute("disabled", false);
     alert("send");
    }
    // prevent default
    e.preventDefault();
  })
}
  edit(){
    this.data.message.message=this.msg;
    this.data.message.frequency_id=this.GetFrequency_Id(this.frequency_name);
    this.data.message.industry_id =this.industry_name;
    this.data.message.dayOfWeek = this.Getday(this.day_name);
    this.data.message.status = this.status;
    this.data.message.note = this.note;
    this.data.message.num_repetitions = this.num_repetitions;
    this.data.message.time_sendind = 	this.time_sendind;

    console.log(this.data.message);
    this.messageService.editMessage(this.data.message.id,this.data.message)
    .subscribe( res=>{ console.log(res);this.dialogRef.close(); }
    );
    
   }
  
   create(){
     let message={

       'message':this.msg,
       'frequency_id':this.GetFrequency_Id(this.frequency_name),
       'industry_id':this.industry_name,
       'dayOfWeek': this.Getday(this.day_name),
       'status' : this.status,
       'note' : this.note,
       'num_repetitions' : this.num_repetitions,
       'time_sendind' :this.time_sendind

     }

     console.log(message);
    
     return this.messageService.postMessage(message).subscribe(res=>{console.log(res); this.dialogRef.close();});
    }

    // getErrorMessage() {
    //   if (this.message.hasError('required')) {
    //     return 'זהו שדה חובה';
    //   }

  // }  

}