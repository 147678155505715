<mat-spinner class="spinner" *ngIf="isLoading"  color="warn"></mat-spinner>
<div class="d-print-none" >
  <div class="example-button-container button-container">
  <button class="btn" mat-fab (click)="create()">
    <mat-icon >add</mat-icon>
  </button>
  <div class="example-button-container">
    <button class="btn" mat-fab  aria-label="Example icon button with a delete icon">
      <label  class="label-btn" for="xls" ><mat-icon>attach_file</mat-icon></label>
      <input style="display: none;" id="xls" type="file" accept=".csv,.xls,.xslsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" (change)="onFileChange($event)" />
    </button>
  </div>
  </div>

  <table mat-table [dataSource]="dataSource" matSort  class="mat-elevation-z8" dir="rtl" justifyContent="center" >

    <!-- num_repetitions Column -->
    <ng-container matColumnDef="num_repetitions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> חזרות</th>
      <td mat-cell *matCellDef="let message"> {{message.num_repetitions}} </td>
    </ng-container>

  <!-- hour Column -->
  <ng-container matColumnDef="time_sendind">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> שעה </th>
    <td mat-cell *matCellDef="let message"  > {{message.time_sendind}} </td>
  </ng-container>

    <!-- day Column -->
    <ng-container matColumnDef="dayOfWeek">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> יום </th>
      <td mat-cell *matCellDef="let message" > 
        {{this.days[(message.dayOfWeek)-1].day}} </td>
    </ng-container>

    <!-- sequence Column -->
    <ng-container matColumnDef="frequency">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> רצף </th>

      <td mat-cell *matCellDef="let message"> {{frequencies[message.frequency_id-1].frequency_name}}</td>
    </ng-container>
    
    <!-- message Column -->
    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> הודעה </th>
      <td mat-cell *matCellDef="let message" > {{message.message}} </td>
    </ng-container>

    <!-- branch Column -->
    <ng-container matColumnDef="industry_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ענף</th>
      <td mat-cell *matCellDef="let message"> {{message.industry_id}} </td>
    </ng-container>

    <!-- Status Column -->  
    <ng-container matColumnDef="status" class="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> סטטוס </th>
      <td mat-cell *matCellDef="let message">
      <mat-slide-toggle [checked]="message.status" [(ngModel)]="message.status"></mat-slide-toggle>
      <button mat-icon-button color="warn" (click)="edit(message)"><mat-icon>create</mat-icon></button>
      <button mat-icon-button color="warn" (click)="delete(message.id)">
      <mat-icon>delete</mat-icon> </button> 
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef= "displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

